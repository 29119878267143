import * as React from "react";
import {
  PlasmicRoundButton,
  DefaultRoundButtonProps
} from "./plasmic/my_evals_marketing/PlasmicRoundButton";

import {
  ButtonRef,
  HtmlAnchorOnlyProps,
  HtmlButtonOnlyProps
} from "@plasmicapp/react-web";

export interface RoundButtonProps extends DefaultRoundButtonProps {
  // Feel free to add any additional props that this component should receive
}
function RoundButton_(props: RoundButtonProps, ref: ButtonRef) {
  const { plasmicProps } = PlasmicRoundButton.useBehavior<RoundButtonProps>(
    props,
    ref
  );
  return <PlasmicRoundButton {...plasmicProps} />;
}

export type ButtonComponentType = {
  (
    props: Omit<RoundButtonProps, HtmlAnchorOnlyProps> & {
      ref?: React.Ref<HTMLButtonElement>;
    }
  ): React.ReactElement;
  (
    props: Omit<RoundButtonProps, HtmlButtonOnlyProps> & {
      ref?: React.Ref<HTMLAnchorElement>;
    }
  ): React.ReactElement;
};
const RoundButton = React.forwardRef(
  RoundButton_
) as any as ButtonComponentType;

export default Object.assign(RoundButton, { __plumeType: "button" });
